import { useEffect, useState } from 'react';
import { Box, Container } from '@mui/material';
import { Helmet } from 'react-helmet';

import { getGamesAPI } from '@api/main';
import { getErrorMessage } from '@api/handleApiError';
import { useToast } from '@context/toastContext';
import GameListResults from '@components/game/GameListResults';
import GameListToolbar from '@components/game/GameListToolbar';

const GameList = () => {
  const showToast = useToast();
  const [games, setGames] = useState([]);

  useEffect(async () => {
    try {
      const result = await getGamesAPI();
      setGames(result.data);
    } catch (error) {
      showToast({
        type: 'error',
        message: getErrorMessage(error)
      });
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Games | GoGame Merchant Portal</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <GameListToolbar />
          <Box sx={{ pt: 3 }}>
            <GameListResults games={games} />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default GameList;
