export const TransactionStatusEnum = {
  WaitForPayment: 1000,
  PaymentProcessing: 3000,
  Success: 5000,
  Canceled: 9000,
  Error: 9999
};

export const TransactionStatusStyle = {
  1000: {
    label: 'Wait for payment',
    color: 'warning'
  },
  3000: {
    label: 'Payment processing',
    color: 'warning'
  },
  5000: {
    label: 'Success',
    color: 'success'
  },
  9000: {
    label: 'Canceled',
    color: 'warning'
  },
  9999: {
    label: 'Error',
    color: 'error'
  }
};
