import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Avatar, Box, Card, Table, TableBody, TableCell, TableHead, TableRow, Typography, Chip } from '@mui/material';
import getInitials from '../../utils/getInitials';

const GameListResults = ({ games, ...rest }) => (
  <Card {...rest}>
    <PerfectScrollbar>
      <Box>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: 120 }}>Game ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {games.map((game) => (
              <TableRow hover key={game.applicationId}>
                <TableCell>{game.merchantAppId}</TableCell>
                <TableCell>
                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex'
                    }}
                  >
                    <Avatar src={game.logoUrl} sx={{ mr: 2 }} variant="rounded">
                      {getInitials(game.name)}
                    </Avatar>
                    <Typography color="textPrimary" variant="body1">
                      {game.name}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  {game.isDisabled ? (
                    <Chip label="Disabled" />
                  ) : (
                    <Chip label="Active" color="primary" />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </PerfectScrollbar>
  </Card>
);

GameListResults.propTypes = {
  games: PropTypes.array.isRequired
};

export default GameListResults;
