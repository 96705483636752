import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Box, Button, Container, Link, TextField, Typography } from '@mui/material';
import { forgotPasswordAPI, resendConfirmationAPI } from '@api/main';
import { useToast } from '@context/toastContext';
import { getErrorMessage } from '@api/handleApiError';

const ForgotPassword = () => {
  const showToast = useToast();
  const [confirmationId, setConfirmationId] = useState(false);

  const handleForgotPassword = async (data) => {
    try {
      const result = await forgotPasswordAPI(data);
      setConfirmationId(result.data.confirmationId);
    } catch (error) {
      showToast({
        type: 'error',
        message: getErrorMessage(error)
      });
    }
  };

  const handleResend = async () => {
    try {
      await resendConfirmationAPI({ confirmationId });
    } catch (error) {
      showToast({
        type: 'error',
        message: getErrorMessage(error)
      });
    }
  };

  if (confirmationId) {
    return (
      <>
        <Helmet>
          <title>Forgot Password | GoGame Merchant Portal</title>
        </Helmet>
        <Box
          sx={{
            backgroundColor: 'background.default',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            justifyContent: 'center'
          }}
        >
          <Container maxWidth="sm">
            <Box sx={{ mb: 3 }}>
              <Typography color="textPrimary" variant="h2">
                Password Reset Email Sent
              </Typography>
              <Typography color="textSecondary" gutterBottom variant="body2">
                Follow the instructions in the email to reset your password.
              </Typography>
            </Box>
            <Typography color="textSecondary" variant="body1">
              Didn&apos;t receive an email?
              <Link href="#" variant="h6" underline="hover" onClick={handleResend}>
                {' Resend'}
              </Link>
            </Typography>
          </Container>
        </Box>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>Forgot Password | GoGame Merchant Portal</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              userName: ''
            }}
            validationSchema={Yup.object().shape({
              userName: Yup.string().email('Must be a valid email').max(255).required('Email is required')
            })}
            onSubmit={handleForgotPassword}
          >
            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
              <form onSubmit={handleSubmit}>
                <Box sx={{ mb: 3 }}>
                  <Typography color="textPrimary" variant="h2">
                    Forgot Password
                  </Typography>
                  <Typography color="textSecondary" gutterBottom variant="body2">
                    Enter your email and we will send you instructions to reset your password.
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(touched.userName && errors.userName)}
                  fullWidth
                  helperText={touched.userName && errors.userName}
                  label="Email Address"
                  margin="normal"
                  name="userName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.userName}
                  variant="outlined"
                />
                <Box sx={{ py: 2 }}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Send Reset Link
                  </Button>
                </Box>
                <Typography color="textSecondary" variant="body1">
                  <Link component={RouterLink} to="/login" variant="h6" underline="hover">
                    Back to login
                  </Link>
                </Typography>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  );
};

export default ForgotPassword;
