import axios from './base';

// *AUTHENTICATION API
/**
 * Login
 * @param {{
  userName: string;
  password: string;
}} data
*/
export const loginAPI = async (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/auth/login`, data, { auth: false });
};

export const forgotPasswordAPI = async (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/auth/forgot-password`, data, { auth: false });
};

export const resetPasswordAPI = async (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/auth/reset-password`, data, { auth: false });
};

export const resendConfirmationAPI = async (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/auth/resend-confirmation`, data, { auth: false });
};

export const getUserInfoAPI = async () => {
  return axios.get(`${process.env.REACT_APP_API_URL}/users/me`);
};

export const updateUserAPI = async (data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/users/me`, data);
};

export const updatePasswordAPI = async (data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/users/change-password`, data);
};

export const healthCheckAPI = async () => {
  return axios.get(`${process.env.REACT_APP_API_URL}/health-check`);
};

// *MERCHANT API
/**
 * Get merchant apps
 * @param {{
 *
}} data
*/
export const getGamesAPI = async () => {
  return axios.get(`${process.env.REACT_APP_API_URL}/merchants/apps`);
};

/**
 * Get merchant settings
 * @param {{
 *
}} data
*/
export const getSettingsAPI = async () => {
  return axios.get(`${process.env.REACT_APP_API_URL}/merchants/settings`);
};

/**
 * Get merchant statistics
 * @param {{
 *
}} data
*/
export const getStatisticsAPI = async () => {
  return axios.get(`${process.env.REACT_APP_API_URL}/merchants/statistics`);
};

/**
 * Get merchant transactions
 * @param {{
 *
}} data
*/
export const getTransactionsAPI = async (
  fromDateUnix,
  toDateUnix,
  applicationId,
  status,
  transactionId,
  merchantOrderId,
  pageSize = 50,
  pageNumber = 1
) => {
  const url = `${process.env.REACT_APP_API_URL}/merchants/transactions`
    + `?fromDateUnix=${fromDateUnix}&toDateUnix=${toDateUnix}`
    + `&applicationId=${applicationId > 0 ? applicationId : ''}&status=${status > 0 ? status : ''}`
    + `&transactionId=${transactionId || ''}&merchantOrderId=${merchantOrderId || ''}`
    + `&pageSize=${pageSize}&pageNumber=${pageNumber}`;
  return axios.get(url);
};
