import { Avatar, Card, CardContent, Grid, Typography, Box } from '@mui/material';
import { green } from '@mui/material/colors';
import MoneyIcon from '@mui/icons-material/Money';

const TotalProfit = ({ title, totalAmount, totalTransactions, ...rest }) => (
  <Card {...rest}>
    <CardContent>
      <Grid container spacing={3} sx={{ justifyContent: 'space-between' }}>
        <Grid item>
          <Typography color="textSecondary" gutterBottom variant="h6">
            {title}
          </Typography>
          <Typography color="textPrimary" variant="h4">
            {`${new Intl.NumberFormat('vi').format(totalAmount)} ₫`}
          </Typography>
        </Grid>
        <Grid item>
          <Avatar
            sx={{
              backgroundColor: green[600],
              height: 56,
              width: 56
            }}
          >
            <MoneyIcon />
          </Avatar>
        </Grid>
      </Grid>
      <Box
        sx={{
          pt: 2,
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <Typography
          sx={{
            color: green[900],
            mr: 1
          }}
          variant="body2"
        >
          {totalTransactions}
        </Typography>
        <Typography color="textSecondary" variant="caption">
          transactions
        </Typography>
      </Box>
    </CardContent>
  </Card>
);

export default TotalProfit;
