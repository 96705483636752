import { Navigate } from 'react-router-dom';
import DashboardLayout from '@components/DashboardLayout';
import MainLayout from '@components/MainLayout';
import Dashboard from '@views/Dashboard';
import Login from '@views/Login';
import NotFound from '@views/NotFound';
import Settings from '@views/Settings';
import ForgotPassword from '@views/ForgotPassword';
import ResetPassword from '@views/ResetPassword';
import TransactionList from '@views/TransactionList';
import GameList from '@views/GameList';

const routes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      { path: 'dashboard', element: <Dashboard /> },
      { path: 'transactions', element: <TransactionList /> },
      { path: 'games', element: <GameList /> },
      { path: 'settings', element: <Settings /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <Login /> },
      { path: 'forgot-password', element: <ForgotPassword /> },
      { path: 'reset-password', element: <ResetPassword /> },
      { path: '404', element: <NotFound /> },
      { path: '/', element: <Navigate to="/app/dashboard" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;
