import { useState } from 'react';
import { Box, Button, Card, CardContent, TextField, Typography, Grid } from '@mui/material';

const GameListToolbar = (props) => {
  const [values, setValues] = useState({
    gameId: '',
    gameName: ''
  });

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleSearch = () => {
    // TODO: Call search api
    console.log('Do search');
  };

  const handleClear = () => {
    setValues({
      gameId: '',
      gameName: ''
    });
    // TODO: Call search api
  };

  return (
    <Box {...props}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <Typography
          variant="h2"
          sx={{
            flex: 1
          }}
        >
          Games
        </Typography>
        <Button sx={{ mx: 1, display: 'none' }}>Export</Button>
      </Box>
      <Box sx={{ mt: 3 }} hidden>
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={3} xs={12}>
                <TextField
                  fullWidth
                  label="Game ID"
                  name="gameId"
                  onChange={handleChange}
                  value={values.gameId}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <TextField
                  fullWidth
                  label="Game Name"
                  name="gameName"
                  onChange={handleChange}
                  value={values.gameName}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Box
                  sx={{
                    display: 'flex',
                    py: 1
                  }}
                >
                  <Button color="primary" variant="contained" sx={{ mx: 1 }} onClick={handleSearch}>
                    Search
                  </Button>
                  <Button color="primary" variant="outlined" onClick={handleClear}>
                    Clear
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default GameListToolbar;
