import { useState } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField
} from '@mui/material';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';

const SettingsView = () => {
  const { merchant } = useSelector((state) => state.auth);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <Helmet>
        <title>Settings | GoGame Merchant Portal</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth="lg">
          <form>
            <Card>
              <CardHeader title="General Settings" />
              <Divider />
              <CardContent>
                <TextField
                  fullWidth
                  margin="normal"
                  label="Name"
                  name="merchantName"
                  value={merchant.merchantName}
                  variant="outlined"
                  disabled
                />
                <TextField
                  fullWidth
                  margin="normal"
                  label="API Key"
                  name="apiKey"
                  value={merchant.apiKey}
                  variant="outlined"
                  disabled
                />
                <FormControl sx={{ mt: 2, mb: 1, width: '100%' }} variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">API Secret</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword ? 'text' : 'password'}
                    value={merchant.apiSecret}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="API Secret"
                    disabled
                  />
                </FormControl>
                <TextField
                  fullWidth
                  margin="normal"
                  label="GoGame Payment Redirect Url"
                  name="paymentRedirectUrl"
                  value={merchant.paymentRedirectUrl}
                  variant="outlined"
                  disabled
                />
              </CardContent>
            </Card>
          </form>
          <Box sx={{ pt: 3 }}>
            <form>
              <Card>
                <CardHeader title="API Endpoints" />
                <Divider />
                <CardContent>
                  {/* <TextField
                    fullWidth
                    margin="normal"
                    label="Server Listing Url"
                    name="serverListingUrl"
                    value={merchant.serverListingUrl}
                    variant="outlined"
                    disabled
                  /> */}
                  <TextField
                    fullWidth
                    margin="normal"
                    label="Product Listing Url"
                    name="productListingUrl"
                    value={merchant.productListingUrl}
                    variant="outlined"
                    disabled
                  />
                  <TextField
                    fullWidth
                    margin="normal"
                    label="Role Verification Url"
                    name="roleVerificationUrl"
                    value={merchant.roleVerificationUrl}
                    variant="outlined"
                    disabled
                  />
                  <TextField
                    fullWidth
                    margin="normal"
                    label="Order Making Url"
                    name="orderMakingUrl"
                    value={merchant.orderMakingUrl}
                    variant="outlined"
                    disabled
                  />
                  <TextField
                    fullWidth
                    margin="normal"
                    label="Payment Callback Url"
                    name="paymentCallbackUrl"
                    value={merchant.paymentCallbackUrl}
                    variant="outlined"
                    disabled
                  />
                </CardContent>
              </Card>
            </form>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default SettingsView;
