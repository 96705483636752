import { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Box, Button, Container, Link, TextField, Typography } from '@mui/material';

import { resetPasswordAPI } from '@api/main';
import { useToast } from '@context/toastContext';
import { getErrorMessage } from '@api/handleApiError';

const ResetPassword = () => {
  const showToast = useToast();
  const navigate = useNavigate();
  const query = new URLSearchParams(useLocation().search);
  const [confirmationKey, setConfirmationKey] = useState('');

  useEffect(() => {
    const key = query.get('key');
    if (key) {
      setConfirmationKey(decodeURI(key));
    }
  }, []);

  const handleResetPassword = async (data) => {
    try {
      await resetPasswordAPI({
        confirmationKey,
        newPassword: data.newPassword
      });
      navigate('/login', { replace: true });
      showToast({
        type: 'success',
        message: 'Your password has been reset successfully'
      });
    } catch (error) {
      showToast({
        type: 'error',
        message: getErrorMessage(error)
      });
    }
  };

  if (!confirmationKey) {
    return (
      <>
        <Helmet>
          <title>Reset Password | GoGame Merchant Portal</title>
        </Helmet>
        <Box
          sx={{
            backgroundColor: 'background.default',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            justifyContent: 'center'
          }}
        >
          <Container maxWidth="sm">
            <Box sx={{ mb: 3 }}>
              <Typography color="textPrimary" variant="h2">
                Reset Password
              </Typography>
              <Typography color="textSecondary" gutterBottom variant="body2">
                Invalid URL
              </Typography>
            </Box>
          </Container>
        </Box>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>Reset Password | GoGame Merchant Portal</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              newPassword: '',
              retypedNewPassword: ''
            }}
            validationSchema={Yup.object().shape({
              newPassword: Yup.string().max(255).required('password is required'),
              retypedNewPassword: Yup.string().oneOf([Yup.ref('newPassword'), null], 'passwords must match')
            })}
            onSubmit={handleResetPassword}
          >
            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
              <form onSubmit={handleSubmit}>
                <Box sx={{ mb: 3 }}>
                  <Typography color="textPrimary" variant="h2">
                    Reset Password
                  </Typography>
                  <Typography color="textSecondary" gutterBottom variant="body2">
                    Create a new password for your account
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(touched.newPassword && errors.newPassword)}
                  fullWidth
                  helperText={touched.newPassword && errors.newPassword}
                  label="New Password"
                  margin="normal"
                  name="newPassword"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.newPassword}
                  variant="outlined"
                  autoComplete="new-password"
                />
                <TextField
                  error={Boolean(touched.retypedNewPassword && errors.retypedNewPassword)}
                  fullWidth
                  helperText={touched.retypedNewPassword && errors.retypedNewPassword}
                  label="Retype New Password"
                  margin="normal"
                  name="retypedNewPassword"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.retypedNewPassword}
                  variant="outlined"
                  autoComplete="new-password"
                />
                <Box sx={{ py: 2 }}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Set New Password
                  </Button>
                </Box>
                <Typography color="textSecondary" variant="body1">
                  <Link component={RouterLink} to="/login" variant="h6" underline="hover">
                    Back to login
                  </Link>
                </Typography>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  );
};

export default ResetPassword;
